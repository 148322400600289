/* src/App.css */
.App {
  position: relative;
  text-align: center;
  background-image: url('assets/background.png');
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: auto 40%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px;
  color: #ffffff;
}

.logo-container {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}

.App-logo {
  height: 100px;
  width: auto;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.App-header {
  margin-top: 20px;
  max-width: 300px;
  width: 100%;
  background-color: rgba(223, 223, 223, 0.6);
  color: #000000;
  border-radius: 8px;
  padding: 30px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
}

.App-link {
  color: #61dafb;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease, transform 0.3s ease;
}

.App-link:hover {
  color: #21a1f1;
  transform: translateY(-2px);
}

button {
  background-color: #61dafb;
  border: none;
  color: white;
  padding: 15px 30px;
  font-size: 24px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #21a1f1;
}

/* Стиль для заблокированной кнопки */
.button-disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.machine-control {
  margin-top: 40px;
  padding: 20px;
  max-width: 300px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  color: #ffffff;
  border-radius: 8px;
  box-shadow: 12px 12px 12px #ffffff33;
  text-align: center;
  margin-bottom: 50px;
}

/* Стили для кастомизации алерта */
.custom-snackbar .MuiAlert-root {
  font-weight: bold; /* Жирный текст */
  font-size: 16px; /* Размер текста */
}

.custom-snackbar {
  top: 20px; /* Позиционирование сверху */
}

.warning-message {
  background-color: #fff3cd; /* Светло-желтый фон для предупреждения */
  color: #000000; /* Темно-желтый текст */
  padding: 15px; /* Внутренние отступы */
  border: 1px solid #ffeeba; /* Обводка чуть темнее фона */
  border-radius: 5px; /* Округленные углы */
  font-weight: bold; /* Жирный шрифт */
  text-align: center; /* Центрирование текста */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Легкая тень для выделения */
  margin-top: 10px; /* Отступ сверху */
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
